import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  updateFound$ = new ReplaySubject<boolean>(1);

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate
  ) {}

  init() {
    if (this.swUpdate.isEnabled) {
      this.checkUpdate();
      this.subscribeUnrecoverableState();
    }
  }

  private checkUpdate() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.swUpdate.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        this.updateFound$.next(updateFound);
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  private subscribeUnrecoverableState() {
    this.swUpdate.unrecoverable.subscribe((_event) => {
      document.location.reload();
    });
  }
}
